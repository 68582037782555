import axios from 'axios';
import constants from '@/config/constants.js';
import { Field, MessageBadge, Countdown, Button } from '@/components';
import Link from '@/components/Link';
import ForgotModal from './ForgotModal.vue';
import { getCookie, setCookie } from '@/utils/Cookies.js';

export default {
  components: {
    Button,
    Field,
    Link,
    ForgotModal,
    MessageBadge,
    Countdown
  },
  created() {
    this.ax = axios.create({
      baseURL: constants.apiEndPoint,
      headers: {
        Authorization: new Date().toDateString()
      }
    });
  },
  mounted() {
    const timeCookie = getCookie('TIME') || null;
    if (timeCookie) {
      this.isBlocked = true;
      this.blockedTime = timeCookie.time;
      this.blockedLimit = timeCookie.limit;
    }
  },
  data() {
    return {
      ax: null,
      isForgotOpen: false,
      loading: false,
      email: '',
      password: '',
      hasError: false,
      isBlocked: false,
      blockedTime: '',
      blockedLimit: null
    };
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.DARK;
    },
    copyrightYear() {
      return this.$moment().format('YYYY');
    }
  },
  methods: {
    async onSubmit() {
      const { email, password } = this;
      if (email && password) {
        this.loading = true;
        try {
          const { data } = await this.Api.post('users/sign_in', { email, password });
          if (data.is_driver) {
            await this.$store.dispatch('deAUTH', {
              authorization: data.Authorization,
              redirect: false
            });
            throw this.$t('auth.noDriver');
          }
          this.$pushNotifications.initialize();
          await this.$store.dispatch('AUTH', data);
          this.$store.dispatch('LANG', data['default_language'] || 'en');
        } catch (error) {
          if (typeof error === 'string') return this.$toast('warning', error);
          this.hasError = false;
          if (error?.data?.http_status_code === '403') {
            setCookie('TIME', {
              time: this.$moment(),
              limit: error.data.data.blocked_user_minutes
            });
            this.blockedTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.isBlocked = true;
            this.blockedLimit = error.data.data.blocked_user_minutes;
          } else this.hasError = true;
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    onTimeUp() {
      this.isBlocked = false;
    }
  },
  watch: {
    email() {
      this.hasError = false;
    },
    password() {
      this.hasError = false;
    }
  }
};
